<template>

  <div class="row" ref="panelForm">

    <div class="col-xs-12 col-md-12 col-sm-12">
      <ValidationObserver v-slot="{ handleSubmit }" ref="formValidator">

        <card>
          <div slot="header">
            <h4 class="card-title">
              {{ formTitle }}
            </h4>
          </div>

          <div class="card-body">
            <div class="row">
              <div class="col-md-12 col-sm-12">

                <div class="row">
                  <div class="col-md-12">
                    <ValidationProvider
                            vid="group_id"
                            rules="required"
                            name="The Group"
                            v-slot="{ passed, failed,errors }">
                      <fg-select
                              name="group_id"
                              size="large"
                              filterable
                              clearable
                              placeholder="Group"
                              :error="failed ? errors[0]: null"
                              :input-classes="'select-default'"
                              :label="'Group'"
                              :list="groupList"
                              :listItemLabel="'name'"
                              :listItemValue="'id'"
                              v-model="formData.group_id">
                      </fg-select>
                    </ValidationProvider>
                  </div>
                </div>

               <div class="row">
                 <div class="col-md-6">
                   <ValidationProvider
                           vid="name"
                           rules="required"
                           name="The Name"
                           v-slot="{ passed, failed,errors }">
                     <fg-input type="text"
                               :error="failed ? errors[0]: null"
                               label="name"
                               name="name"
                               @keyup="generateSomeFields"
                               fou
                               v-model="formData.name">
                     </fg-input>
                   </ValidationProvider>
                 </div>
                 <div class="col-md-6">
                   <ValidationProvider
                           vid="ident"
                           rules="required"
                           name="The Ident"
                           v-slot="{ passed, failed,errors }">
                     <fg-input type="text"
                               :error="failed ? errors[0]: null"
                               :disabled="editMode === true"
                               label="Panel Ident"
                               name="ident"
                               fou
                               v-model="formData.ident">
                     </fg-input>
                   </ValidationProvider>
                 </div>
               </div>
                <fg-input type="text"
                            label="Main Heading"
                            name="main_heading"
                            fou
                            v-model="formData.main_heading">
                  </fg-input>
                <fg-input type="text"
                          label="Title"
                          name="title"
                          fou
                          v-model="formData.title">
                </fg-input>
                <ValidationProvider
                        vid="google_ads_code"
                        rules=""
                        name="The Google ads code"
                        v-slot="{ passed, failed,errors }">
                  <fg-input type="text"
                            :error="failed ? errors[0]: null"
                            label="google_ads_code"
                            name="google_ads_code"
                            fou
                            v-model="formData.google_ads_code">
                  </fg-input>
                </ValidationProvider>
                <div class="row">
                  <div class="col-md-6">
                    <ValidationProvider
                      vid="color"
                      rules=""
                      name="The Color"
                      v-slot="{ passed, failed,errors }">
                      <fg-input type="text"
                                :error="failed ? errors[0]: null"
                                label="Color"
                                name="color"
                                fou
                                v-model="formData.color">
                      </fg-input>
                    </ValidationProvider>
                  </div>
                  <div class="col-md-6">
                    <ValidationProvider
                      vid="header_color"
                      rules=""
                      name="The Header Color"
                      v-slot="{ passed, failed,errors }">
                      <fg-select
                        name="header_color"
                        size="large"
                        filterable
                        clearable
                        placeholder="Header Color"
                        :error="failed ? errors[0]: null"
                        :input-classes="'select-default'"
                        :label="'Header Color'"
                        :list="headerColorList"
                        :listItemLabel="'label'"
                        :listItemValue="'value'"
                        v-model="formData.header_color">
                      </fg-select>
                    </ValidationProvider>
                  </div>
                </div>
              </div>
              <div class="col-md-12 com-sm-12 mb-2 mt-2">
                <div class="form-group">
                  <label>Content</label>
                  <editor
                    v-model="formData.content"
                    :api-key="editorKey"
                    :init='editorConfig'
                  />
                </div>

              </div>
              <div class="col-md-12 col-sm-12">

                <div class="form-group">
                  <label>Image</label>
                  <el-tooltip placement="right" v-if="getBannerImageInfo()">
                    <div slot="content">{{getBannerImageInfo()}}</div>
                    <span style="margin: 0 5px;">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-question-circle" viewBox="0 0 16 16">
                      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                      <path d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286zm1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94z"/>
                    </svg>
                    </span>
                  </el-tooltip>
                  <prime-uploader
                    :preview-width="'200px'"
                    :preview-height="'200px'"
                    :elementNum="1"
                    v-model="formData.image"
                  >
                  </prime-uploader>
                </div>
                <div class="form-group">
                  <label>Icon</label>
                  <el-tooltip placement="right">
                    <div slot="content"></div>
                    <span style="margin: 0 5px;">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-question-circle" viewBox="0 0 16 16">
                      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                      <path d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286zm1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94z"/>
                    </svg>
                    </span>
                  </el-tooltip>
                  <prime-uploader
                    :preview-width="'200px'"
                    :preview-height="'200px'"
                    :elementNum="1"
                    v-model="formData.icon"
                  >
                  </prime-uploader>
                </div>
                <ValidationProvider
                        vid="video_type"
                        rules=""
                        name="The Video Type"
                        v-slot="{ passed, failed,errors }">
                  <fg-select
                          name="video_type"
                          size="large"
                          filterable
                          clearable
                          placeholder="Video Type"
                          :error="failed ? errors[0]: null"
                          :input-classes="'select-default'"
                          :label="'Video Type'"
                          :list="VideoTypes"
                          :listItemLabel="'label'"
                          :listItemValue="'value'"
                          v-model="formData.video_type">
                  </fg-select>
                </ValidationProvider>
                <div class="form-group" v-if="formData.video_type === 'UPLOADED'">
                  <label>Uploaded Video</label>
                  <el-tooltip placement="right" v-if="getBannerVideoInfo()">
                    <div slot="content">{{getBannerVideoInfo()}}</div>
                    <span style="margin: 0 5px;">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-question-circle" viewBox="0 0 16 16">
                      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                      <path d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286zm1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94z"/>
                    </svg>
                    </span>
                  </el-tooltip>
                  <prime-video-uploader
                          :preview-width="'200px'"
                          :preview-height="'200px'"
                          :maxFileSize="50000000"
                          v-model="formData.uploaded_video"
                  >
                  </prime-video-uploader>
                </div>
                <div class="form-group" v-if="formData.video_type == 'EMBEDDED'">
                  <el-tooltip placement="right" v-if="getBannerVideoInfo()">
                    <div slot="content">{{getBannerVideoInfo()}}</div>
                    <span style="margin: 0 5px;">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-question-circle" viewBox="0 0 16 16">
                      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                      <path d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286zm1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94z"/>
                    </svg>
                    </span>
                  </el-tooltip>
                  <ValidationProvider
                          vid="embedded_video"
                          rules=""
                          name="The Embedded Video"
                          v-slot="{ passed, failed,errors }">
                    <fg-input type="text"
                              :error="failed ? errors[0]: null"
                              label="Embedded Video"
                              name="embedded_video"
                              fou
                              v-model="formData.embedded_video">
                    </fg-input>
                  </ValidationProvider>
                  <div v-html="formData.embedded_video">
                  </div>
                </div>
                <div class="form-group">
                  <label>Gallery</label>
                  <el-tooltip placement="right" v-if="false">
                    <div slot="content"></div>
                    <span style="margin: 0 5px;">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                             class="bi bi-question-circle" viewBox="0 0 16 16">
                      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                      <path
                        d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286zm1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94z"/>
                    </svg>
                    </span>
                  </el-tooltip>
                  <prime-uploader
                    :preview-width="'200px'"
                    :preview-height="'200px'"
                    :elementNum="99"
                    :maxFileSize="2000000"
                    :isEditMode="editMode"
                    v-model="formData.gallery"
                  >
                  </prime-uploader>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <fg-select
                            name="url_target"
                            size="large"
                            filterable
                            clearable
                            placeholder="Redirection Type"
                            :input-classes="'select-default'"
                            :label="'Target'"
                            :list="redirectionTargets"
                            :listItemLabel="'label'"
                            :listItemValue="'value'"
                            v-model="formData.url_target">
                    </fg-select>
                  </div>
                  <div class="col-md-6">
                    <ValidationProvider
                            vid="redirection_type"
                            rules="required"
                            name="Redirection Type"
                            v-slot="{ passed, failed,errors }">
                      <fg-select
                              name="redirection_type"
                              size="large"
                              filterable
                              clearable
                              :error="failed ? errors[0]: null"
                              placeholder="Redirection Type"
                              :input-classes="'select-default'"
                              :label="'redirection Type'"
                              :list="redirectionTypes"
                              :listItemLabel="'label'"
                              :listItemValue="'value'"
                              v-model="formData.redirection_type">
                      </fg-select>
                    </ValidationProvider>
                  </div>
                </div>
                 <div class="row">
                   <div class="col-md-6">
                     <fg-input type="text"
                               label="Destination Url"
                               name="destination_url"
                               fou
                               v-model="formData.destination_url">
                     </fg-input>
                   </div>
                   <div class="col-md-6">
                     <fg-input type="text"
                               label="Destination Url Title"
                               name="destination_url_title"
                               fou
                               v-model="formData.destination_url_title">
                     </fg-input>
                   </div>
                 </div>
                <label>List Items</label>
                <div class="col-12">
                  <template class="row" v-for="(listItem,listItemIndex) in formData.list_items">
                    <fg-input type="text"
                              :key="listItemIndex"
                              :label="'Item '+(listItemIndex+1)"
                              v-model="formData.list_items[listItemIndex]">
                    </fg-input>
                    <div class="col-sm-1 align-self-center mt-3">
                      <l-button
                        @click="removeItem(formData.list_items, listItemIndex)"
                        type="danger" size="sm">
                        <i class="fa fa-trash"></i>
                      </l-button>
                    </div>
                  </template>
                  <l-button
                    class="mt-3"
                    type="success"
                    @click="addNewItem(formData.list_items)"
                    size="sm">
                    <i class="fa fa-plus"></i>
                  </l-button>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <label class="card-label">Start Date</label>
                    <fg-input v-model="formData.start_date"
                              value-format="YYYY-MM-DD HH:MM:SS"
                              type="datetime-local"
                              placeholder="Chose panel publish date"
                    >
                    </fg-input>
                  </div>
                  <div class="col-md-6">
                    <label class="card-label">End Date</label>
                    <fg-input v-model="formData.end_date"
                              value-format="YYYY-MM-DD HH:MM:SS"
                              type="datetime-local"
                              placeholder="Chose panel expire date"
                    >

                    </fg-input>
                  </div>
                </div>


                <div class="form-group pt-4">
                  <label>Is Active</label>&nbsp;
                  <l-switch v-model="formData.is_active">
                    <i class="fa fa-check" slot="on"></i>
                    <i class="fa fa-times" slot="off"></i>
                  </l-switch>
                </div>

              </div>
            </div>

          </div>

          <div class="card-footer text-right">
            <l-button :disabled="entityNotFoundError || submitting" @click.prevent="handleSubmit(submit)"
                      nativeType="submit"
                      type="info" wide>Submit
            </l-button>
            <l-button :disabled="entityNotFoundError || submitting" @click.prevent="handleSubmit(submit(true))"
                      nativeType="submit"
                      type="default" wide>Save
            </l-button>
            <l-button @click="$router.push('/panels/list')" type="danger" wide>Cancel
            </l-button>
          </div>
        </card>

      </ValidationObserver>

    </div>

  </div>
</template>
<script>
import {extend} from "vee-validate";
import {Select, Option,Tooltip} from 'element-ui';
import FgSelect from "@/components/Inputs/formGroupSelect";
import FormGroupTextArea, {ImageUploader, Switch as LSwitch} from "@/components";
import Editor from '@tinymce/tinymce-vue'
import {mapGetters} from "vuex";
import {PrimeUploader} from '@/components';
import {PrimeVideoUploader} from '@/components';


extend("required", {
  message: "{_field_} is required"
});

export default {

  components: {
    FormGroupTextArea,
    [Option.name]: Option,
    [Select.name]: Select,
    [Tooltip.name]: Tooltip,
    FgSelect,
    LSwitch,
    PrimeUploader,
    ImageUploader,
    PrimeVideoUploader,
    'editor': Editor
  },

  data() {
    return {
      editMode: false,
      loader: {},
      id: undefined,
      editorConfig: this.getEditorConfig(),

      formTitle: "",
      entityNotFoundError: false,
      submitting: false,
      editorKey: process.env.VUE_APP_TINY_EDITOR_KEY,

      formData: {
        group_id: "",
        name: "",
        ident: "",
        main_heading: "",
        content: "",
        image: "",
        destination_url: "",
        destination_url_title: "",
        url_target: "",
        start_date: null,
        end_date: null,
        is_active: true,
        redirection_type: "",
        google_ads_code: "",
        video_type: '',
        uploaded_video: "",
        embedded_video: "",
        title: "",
        icon: "",
        gallery: [],
        list_items: [],
        color: "",
        header_color: "",

      },

      groupList: [],
      redirectionTargets: [],
      redirectionTypes: [],
      VideoTypes: [],
      headerColorList: [],

    };
  },

  computed: {
    ...mapGetters({
      mediaInfo: "global/mediaInfo"
    })
  },
  mounted() {
    this.loader = this.$loading.show({
      container: this.$refs.panelForm
    });

    this.axios.post("panels/builder").then((response) => {
      this.groupList = response.data.groups;
      this.redirectionTargets = response.data.redirectionTargets;
      this.redirectionTypes = response.data.redirectionTypes;
      this.formData.redirection_type = response.data.redirectionTypes[0].value;
      this.VideoTypes = response.data.videoTypes;
      this.headerColorList = [
        {
          label: 'LIGHT',
          value: 'LIGHT'
        },
        {
          label: 'DARK',
          value: 'DARK'
        },

      ]

      this.id = this.$route.params['id'];

      if (this.id !== undefined) {
        this.editMode = true;
        this.formTitle = "Edit Panel";
        this.getPanel();
      } else {
        this.editMode = false;
        this.formTitle = "Add Panel";
        this.loader.hide();
      }

    }).catch((error) => {
      console.error(error);
    })
  },


  methods: {
    getPanel() {
      let data = {
        'id' : this.id,
      };
      this.axios.post("panels/get",data).then((response) => {
        this.formData = response.data;
        this.vueDate();
        this.loader.hide();
      }).catch((error) => {
        if (error.response.status === 404) {
          this.entityNotFoundError = true;
          this.$notify({
            message: "Panel Not Found",
            timeout: 2000,
            type: 'danger'
          });
          this.loader.hide();

        } else {
          console.error(error);
        }
      })
    },
    generateSomeFields() {

      if(!this.editMode){
        let newUrl = '';
        if (this.formData.name) {
          newUrl = this.formData.name.replace(/\s+/g, "_").toLowerCase();
          newUrl = newUrl.replace(/[^ءأ-يa-zA-Z0-9-]/g, "_");
          newUrl = newUrl.replace(/([-]+)/g, "_");
        }

        this.formData.ident = newUrl;
      }
    },

    submit(isSave = false) {
      let request;
      let successMessage;
      this.submitting = true;
      if (this.formData.video_type == "UPLOADED"){
          this.formData.embedded_video = '';
      }else{
          this.formData.uploaded_video = '';
      }
      this.laravelDate();
      if (this.editMode === true) {
        request = this.axios.put("panels/update/" + this.id, this.formData);
        successMessage = "Panel Updated Successfully";
      } else {
        request = this.axios.post("panels/create", this.formData);
        successMessage = "Panel Added Successfully";
      }

      request.then((response) => {
        this.$notify({
          message: successMessage,
          timeout: 1000,
          type: 'success'
        })
        if (isSave){
          window.location.href = "/panels/edit/" + response.data.id;
        }else{
          this.$router.push("/panels/list");
        }
      }).catch((error) => {
        if (error.response.status === 422) {
          this.$refs.formValidator.setErrors(error.response.data.errors);
        } else {
          console.log(error.response)
        }
      }).finally(() => {
        this.submitting = false;
      })
    },

    getBannerImageInfo() {
      if(this.mediaInfo && this.mediaInfo["panel_image"]) {
        return this.mediaInfo["panel_image"];
      } else {
        return "";
      }
    },

    getBannerVideoInfo() {
      if(this.mediaInfo && this.mediaInfo["panel_video"]) {
        return this.mediaInfo["panel_video"];
      } else {
        return "";
      }
    },
    vueDate(){
        this.formData.start_date = (this.formData.start_date)? this.formData.start_date.replace(' ', 'T'):this.formData.start_date;
        this.formData.end_date = (this.formData.end_date)? this.formData.end_date.replace(' ', 'T'):this.formData.end_date;
    },
    laravelDate(){
        this.formData.start_date = (this.formData.start_date)? this.formData.start_date.replace('T', ' '):this.formData.start_date;
        this.formData.end_date = (this.formData.end_date)? this.formData.end_date.replace('T', ' '):this.formData.end_date;
    },
    addNewItem(listItems) {
      listItems.push('');
    },
    removeItem(listItems, index) {
      listItems.splice(index, 1);
    },

  }
}
</script>
